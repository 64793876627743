
main {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100% !important;
  /* min-height: 100vh; */
  margin: auto;
}

.mainParent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-bottom: 20px;
}

.containerBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  width: 100% !important;
}

.containerBoxMain,
.containerBoxMainRight {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100% !important;
}
