.simulador {
  width: 100%;
}

.header-simulador {
  font-size: 1.3em !important;
}

.disclaimer-simulador {
  width: 90% !important;
  font-size: 0.8em !important;
}

.simulador {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  max-width: 800px;
  margin: auto;
  margin-bottom: 30px;
  border-radius: 15px;
}

.customFormFeedback {
  font-size: 0.75em;
  font-weight: 500;
  color: var(--bs-red);
  margin: 0;
  padding-top: 0.05em;
}

.divButtons {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.stretchLabel {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  padding: 0.7em;
}

.stretchLabel label {
  width: 100%;
  padding-left: 0.5em;
}

.stretchLabel .form-check-input {
  margin: 0;
}

.stretchLabel:hover {
  /* background-color: var(--bg-contrast-soft-darker); */
  border-color: var(--bs-cyan) !important;
  cursor: pointer;
  font-weight: 600;
  /* font-size: 1.2em; */
  color: var(--bs-warning);
}

.stretchLabel .form-check-input:hover,
.stretchLabel label:hover {
  cursor: pointer;
}

.stretchLabel input {
  width: 1.35em;
  height: 1.35em;
}

.radioYesNo .form-check-input,
.radioYesNo .form-check,
.radioYesNo.form-check {
  margin: 0 !important;
  color: var(--bs-white);
}

.divYesNo,
.radioYesNo {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.radioYesNo {
  padding: 0.2em 0.7em;
}

.radioYesNo label {
  min-width: 50px;
  padding: 2px 5px;
  text-align: center;
}

.divYesNo div:first-child {
  margin-right: 1em !important;
}

.radioYesNo input:checked {
  background-color: var(--bs-warning);
  border-color: var(--bs-warning);
}

.radioYesNo:has(input:checked) {
  color: var(--bs-warning);
}

.radioYesNo:hover,
.radioYesNo:hover label,
.radioYesNo:hover input {
  cursor: pointer;
  font-weight: 700;
  color: var(--bs-warning);
}

.invalid-box {
  border-radius: 1em;
  border: 2px solid var(--bs-red);
  background-color: var(--bg-overlay-red) !important;
  padding: 1.5rem !important;
}

#swalDivErrorSimulacao {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  padding: 1em !important;
  color: var(--text-contrast) !important;
  font-size: 0.9em !important;
}

#swalDivErrorSimulacao-title {
  font-size: 1.2em !important;
  color: var(--text-contrast-color-red) !important;
}

/* childs */
#swalDivErrorSimulacao-items span {
  display: block !important;
  padding: 0.5em !important;
}

#swalDivErrorSimulacao-items {
  display: flex !important;
  flex-direction: column !important;
  align-items: flex-start !important;
  padding: 0.5em !important;
}

.bgGradBlue {
  background: rgb(0,142,255);
  background: -moz-radial-gradient(circle, rgba(0,142,255,1) 0%, rgba(0,10,92,1) 100%);
  background: -webkit-radial-gradient(circle, rgba(0,142,255,1) 0%, rgba(0,10,92,1) 100%);
  background: radial-gradient(circle, rgba(0,142,255,1) 0%, rgba(0,10,92,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#008eff",endColorstr="#000a5c",GradientType=1);

}

@media screen and (min-width: 768px) {
  .simulador {
    width: 90%;
  }

  .header-simulador {
    font-size: 1.5em !important;
  }

  .disclaimer-simulador {
    width: 70% !important;
    font-size: 0.8em !important;
  }
}
