.logoHeader {
  display: flex;
  justify-content: center;
  padding-top: 20px;
  width: 100%;
}

.formLogoHeader {
  width: 250px !important;
  max-width: 90% !important;
  margin: auto;
}
