.hero {
  width: 100% !important;
  overflow: hidden;
  position: relative;
}

.bghero {
  background-size: cover;
  background-position: center;
  width: 100% !important;
} 

.heroContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 20px;
}

.zoomInOut11 {
  animation: zoomInOut 11s ease-in-out infinite;
}

@keyframes zoomInOut {
  0%, 100% {
    transform: scale(1); /* tamanho inicial */
  }
  50% {
    transform: scale(1.1); /* aumenta no meio da animação */
  }
}