footer {
  position: fixed;
  bottom: 0;
  width: 100% !important;
  background-color: var(--bg-footer);
  color: var(--text-footer);
  padding: 3px  10px !important;
}

.footer {
  max-width: var(--max-container);
  margin: auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
}

@media screen and (max-width: 600px) {
  .footer {
    flex-direction: column !important;
  }

  .footer-sp-fale {
    display: none !important;
  }

  .footer-sp-reg {
    font-size: 0.9em !important;
  }
}
