.cardBoxLine {
  width: 100%;
  display: grid;
  gap: 15px;
  padding: 2em 1em;
}

.cardItem {
  background-color: var(--bg-menu);
  color: var(--text-menu);
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 300px;
  min-height: 200px;
  transition: all 0.3s;
  transform-origin: center;
}

.cardItem:hover {
  background-color: var(--bg-menu-hover);
  color: var(--text-menu-hover);
  transform: scale(1.05);
}
