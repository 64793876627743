/* font size and weight */

.fw100 {
  font-weight: 100 !important;
}

.fw200 {
  font-weight: 200 !important;
}

.fw300 {
  font-weight: 300 !important;
}

.fw400 {
  font-weight: 400 !important;
}

.fw500 {
  font-weight: 500 !important;
}

.fw600 {
  font-weight: 600 !important;
}

.fw700 {
  font-weight: 700 !important;
}

.fw800 {
  font-weight: 800 !important;
}

.fw900 {
  font-weight: 900 !important;
}

.fs05 {
  font-size: 0.5em !important;
}

.fs06 {
  font-size: 0.6em !important;
}

.fs07 {
  font-size: 0.7em !important;
}

.fs08 {
  font-size: 0.8em !important;
}

.fs09 {
  font-size: 0.7em !important;
}

.fs10 {
  font-size: 0.8em !important;
}

.fs12 {
  font-size: 0.9em !important;
}

.fs14 {
  font-size: 1em !important;
}

.fs16 {
  font-size: 1.1em !important;
}

.fs18 {
  font-size: 1.2em !important;
}

.fs20 {
  font-size: 1.3em !important;
}

.fs22 {
  font-size: 1.4em !important;
}

.fs24 {
  font-size: 1.5em !important;
}

.fs26 {
  font-size: 1.6em !important;
}

.fs28 {
  font-size: 1.7em !important;
}

.fs30 {
  font-size: 1.8em !important;
}

.fs32 {
  font-size: 1.9em !important;
}

.fs34 {
  font-size: 2em !important;
}

.fs36 {
  font-size: 2.1em !important;
}

.fs38 {
  font-size: 2.2em !important;
}

.fs40 {
  font-size: 2.3em !important;
}

.fs42 {
  font-size: 2.4em !important;
}

.fs44 {
  font-size: 2.5em !important;
}

.fs46 {
  font-size: 2.6em !important;
}

.fs48 {
  font-size: 2.7em !important;
}

.fs50 {
  font-size: 2.8em !important;
}

.fs52 {
  font-size: 2.9em !important;
}

.fs54 {
  font-size: 3em !important;
}

.fsr05 {
  font-size: 0.5rem !important;
}

.fsr06 {
  font-size: 0.6rem !important;
}

.fsr07 {
  font-size: 0.7rem !important;
}

.fsr08 {
  font-size: 0.8rem !important;
}

.fsr09 {
  font-size: 0.7rem !important;
}

.fsr10 {
  font-size: 0.8rem !important;
}

.fsr12 {
  font-size: 0.9rem !important;
}

.fsr14 {
  font-size: 1rem !important;
}

.fsr16 {
  font-size: 1.1rem !important;
}

.fsr18 {
  font-size: 1.2rem !important;
}

.fsr20 {
  font-size: 1.3rem !important;
}

.fsr22 {
  font-size: 1.4rem !important;
}

.fsr24 {
  font-size: 1.5rem !important;
}

.fsr26 {
  font-size: 1.6rem !important;
}

.fsr28 {
  font-size: 1.7rem !important;
}

.fsr30 {
  font-size: 1.8rem !important;
}

.fsr32 {
  font-size: 1.9rem !important;
}

.fsr34 {
  font-size: 2rem !important;
}

.fsr36 {
  font-size: 2.1rem !important;
}

.fsr38 {
  font-size: 2.2rem !important;
}

.fsr40 {
  font-size: 2.3rem !important;
}

.fsr42 {
  font-size: 2.4rem !important;
}

.fsr44 {
  font-size: 2.5rem !important;
}

.fsr46 {
  font-size: 2.6rem !important;
}

.fsr48 {
  font-size: 2.7rem !important;
}

.fsr50 {
  font-size: 2.8rem !important;
}

.fsr52 {
  font-size: 2.9rem !important;
}

.fsr54 {
  font-size: 3rem !important;
}

.ffReg {
  font-family: var(--font-regular) !important;
}

.ffSoft {
  font-family: var(--font-soft) !important;
}

.faRight {
  text-align: right !important;
}

.faCenter {
  text-align: center !important;
}

.faLeft {
  text-align: left !important;
}

.faJustify {
  text-align: justify !important;
}

.lh05 {
  line-height: 0.5em !important;
}

.lh06 {
  line-height: 0.6em !important;
}

.lh07 {
  line-height: 0.7em !important;
}

.lh08 {
  line-height: 0.8em !important;
}

.lh09 {
  line-height: 0.9em !important;
}

.lh10 {
  line-height: 1em !important;
}

.lh11 {
  line-height: 1.1em !important;
}

.lh12 {
  line-height: 1.2em !important;
}

.lh13 {
  line-height: 1.3em !important;
}

.lh14 {
  line-height: 1.4em !important;
}

.lh15 {
  line-height: 1.5em !important;
}

.lh16 {
  line-height: 1.6em !important;
}

.lh17 {
  line-height: 1.7em !important;
}

.lh18 {
  line-height: 1.8em !important;
}

.lh19 {
  line-height: 1.9em !important;
}

.lh20 {
  line-height: 2em !important;
}

.lh21 {
  line-height: 2.1em !important;
}

.lh22 {
  line-height: 2.2em !important;
}

.lh23 {
  line-height: 2.3em !important;
}

.lh24 {
  line-height: 2.4em !important;
}

.lh25 {
  line-height: 2.5em !important;
}

.lh26 {
  line-height: 2.6em !important;
}

.lh27 {
  line-height: 2.7em !important;
}

.lh28 {
  line-height: 2.8em !important;
}

.lh29 {
  line-height: 2.9em !important;
}

.lh30 {
  line-height: 3em !important;
}

/* kept for compatibility */
/* 
.f06 {
  font-size: 0.6em !important;
}

.f07 {
  font-size: 0.7em !important;
}

.f08 {
  font-size: 0.8em !important;
}

.f09 {
  font-size: 0.9em !important;
}

.f10 {
  font-size: 1em !important;
}

.f11 {
  font-size: 1.1em !important;
}

.f12 {
  font-size: 1.2em !important;
}

.f13 {
  font-size: 1.3em !important;
}

.f14 {
  font-size: 1.4em !important;
}

.f15 {
  font-size: 1.5em !important;
}

.f16 {
  font-size: 1.6em !important;
}

.f18 {
  font-size: 1.8em !important;
}

.f20 {
  font-size: 2em !important;
}

.fw300 {
  font-weight: 300 !important;
}

.fw400 {
  font-weight: 400 !important;
}

.fw500 {
  font-weight: 500 !important;
}

.fw600 {
  font-weight: 600 !important;
}

.fw700 {
  font-weight: 700 !important;
}

.fw800 {
  font-weight: 800 !important;
}

.txt100 {
  font-weight: 100 !important;
}

.txt200 {
  font-weight: 200 !important;
}

.txt300 {
  font-weight: 300 !important;
}

.txt400 {
  font-weight: 400 !important;
}

.txt500 {
  font-weight: 500 !important;
} */
