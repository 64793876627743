.bghero_01 {
  background-image: url('../img/ia/bgcorp.jpg');
}

.bgHandshake {
  background-image: url('../img/tablet.jpg');
}

.bgGradBlue {
  background: rgb(0,142,255);
  background: -moz-radial-gradient(circle, rgba(0,142,255,1) 0%, rgba(0,10,92,1) 100%);
  background: -webkit-radial-gradient(circle, rgba(0,142,255,1) 0%, rgba(0,10,92,1) 100%);
  background: radial-gradient(circle, rgba(0,142,255,1) 0%, rgba(0,10,92,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#008eff",endColorstr="#000a5c",GradientType=1);

}

.appBg {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.p0 {
  padding: 0 !important;
}

.m0 {
  margin: 0 !important;
}

.hovbgYellow:hover .cYellow {
  color: var(--bs-blue) !important;
}

.hovbgYellow:hover .cWhite {
  color: var(--bs-gray-800) !important;
}

.hovBgContrastFlow:hover {
  background-color: var(--bg-contrast-soft);
}

.hovbgLightBlueTransp:hover .cBlue {
  color: var(--bs-gray-100) !important;
}

.hovbgLightBlueTransp:hover .cGray800 {
  color: var(--bs-gray-600) !important;
}

/* filter table input */

.filterTable {
  border: 0.5px solid var(--bs-gray-200);
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 0.8em;
  width: 80%;
  max-width: 400px;
}

.filterTable:focus {
  outline: 1px solid var(--bs-cyan);
}

.filterTable:hover {
  border: 0.5px solid var(--bs-green);
}

/* table */

.tableSimulacoes {
  text-align: center;
  font-size: 0.8em;
}

.tableSimulacoes td {
  vertical-align: middle;
}

.tableSimulacoes a {
  background-color: unset;
}

/*  */

.fvaCenter {
  vertical-align: middle;
}

#homeNewsCharts .recharts-tooltip-wrapper,
#homeTaxasCharts .recharts-tooltip-wrapper {
  background-color: var(--bg-menu-soft);
  color: var(--text-menu);
  border-radius: 5px;
  padding: 5px;
  font-size: 0.8em;
}

#homeNewsCharts .recharts-default-tooltip,
#homeTaxasCharts .recharts-default-tooltip {
  background-color: transparent !important;
  border: none !important;
}

/* modals */

.cstModal .modal-content {
  background-color: var(--bg-contrast-soft);
  color: var(--text-menu);
  padding: 1.2em;
  font-size: 0.9em;
  border-radius: 1.3em;
}

.cstModal .modal-header {
  color: var(--text-contrast-color);
  font-family: var(--font-soft);
}

/*  prioridades */

.drop-menu-out {
  background-color: var(--bg-menu) !important;
  font-size: 0.9em !important;
}

.drop-menu-out.show {
  display: flex !important;
  flex-direction: column !important;
  background-color: var(--bg-menu) !important;
  font-size: 0.9em !important;
}

.drop-menu-out > .drop-menu-out-line {
  padding: 0.2em !important;
  transition: all 0.3s !important;
}

.drop-menu-out > span:hover {
  font-weight: 700 !important;
  font-size: 1.3em !important;
  padding: 0.8em !important;
  cursor: pointer;
}

.dropStart {
  margin: 0;
  padding: 0;
}

.dropStart .btn {
  margin: 0;
  padding: 0;
}

.dropOrgaos {
  margin: 0;
  padding: 0;
}

.dropOrgaos .drop-menu-out-line {
  padding: 0.2em !important;
  transition: all 0.3s !important;
  width: 300px !important;
}

.dropOrgaos .drop-menu-out-line:hover {
  background-color: aquamarine;
}

.exigenciaDocument {
  background-color: white;
  padding: 0;
  width: 19cm;
}

.pageSize {
  width: 21cm;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.exigenciaText {
  margin: 2em 1em;
  padding: 3em 2em;
  color: black;
  text-align: justify;
  font-size: 0.9em;

  border-top: 1px solid var(--bs-gray-200);
  border-bottom: 1px solid var(--bs-gray-200);
}

#downloadProcessoReport {
  width: 19cm;
  height: 40cm;
}

.divButtons {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 1em 0;
}

.colored-toast {
  font-family: var(--font-soft) !important;
  font-size: 12px;
  font-weight: 600;
}

.colored-toast.swal2-icon-success {
  background-color: #72bb48 !important;
}

.colored-toast.swal2-icon-error {
  background-color: #f27474 !important;
}

.colored-toast.swal2-icon-warning {
  background-color: #f8bb86 !important;
}

.colored-toast.swal2-icon-info {
  background-color: #3fc3ee !important;
}

.colored-toast.swal2-icon-question {
  background-color: #87adbd !important;
}

.colored-toast .swal2-title {
  color: white;
}

.colored-toast .swal2-close {
  color: white;
}

.colored-toast .swal2-html-container {
  color: white;
}
