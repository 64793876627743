.drag-drop {
  display: flex;
  margin: auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
}

.document-uploader {
  border: 2px dashed var(--w-link-color);
  background-color: var(--w-light-grey-t);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 8px;
  cursor: pointer;
  width: 100%;

  &.active {
    border-color: var(--w-green);
  }
}

.filesList {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
}

.file-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  border: 1px solid var(--border-color);
  border-radius: 8px;

  .file-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.25rem;
    flex: 1;

    p {
      margin: 0;
      font-size: 14px;
      word-wrap: break-word;
      /* white-space: pre-wrap;  */
    }
  }

  .file-actions {
    cursor: pointer;

    /* svg {
      font-size: 18px;
    } */

    &:hover {
      svg {
        color: var(--bs-danger);
      }
    }
  }
}

.browse-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
  border: 1px solid var(--border-color);
  border-radius: 8px;
  cursor: pointer;
  background-color: var(--primary-color);

  &:hover {
    background-color: transparent;
  }
}
