.boximagetext {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 2em 1em;
}

.boxImage {
  display: flex;
  width: 40%;
  max-width: 350px;
  justify-content: flex-start;
}

.boximagetext.flex-row-reverse > .boxImage {
  justify-content: flex-end !important;
}

.boxImage img {
  border-radius: 15px;
  max-width: 400px;
}

.boxText {
  background-color: var(--bg-overlay-darkest);
  color: var(--text-overlay-dark);
  padding: 35px;
  border-radius: 10px;
  max-width: 900px;
  /* width: 100%; */
  z-index: 0;
}

.boxTextImageInside, .boxTextImage  {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.boxTextImage {
  width: 25%;
  padding: 15px;
}

.boxTextImage > img{
  border-radius: 15px;
  max-height: 400px;
}

.boxTextText {
  width: 70%;
  padding: 20px;
}

@media screen and (max-width: 1100px) {
  .boximagetext {
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
  }

  .boximagetext.flex-row-reverse > .boxImage,
  .boxImage {
    width: 98%;
    max-width: 98%;
    padding-bottom: 20px;
    margin: auto;
    display: flex;
    justify-content: center !important;
    align-items: center !important;
  }

  .boxImage img {
    width: 100%;
  }

  .boxText {
    width: 98%;
    padding: 20px;
  }
}
