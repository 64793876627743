
.noty-icon {
  position: relative;
  display: inline;
}

.noty-num {
  position: absolute;
  right: 0;
  font-size: 10px;
  font-weight: bold;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;
  border-radius: 20px;
  width: 15px;
  height: 15px;
}
