
.liNews {
  padding: 0 !important;
  margin: 0 !important;
}

.liContent {
  padding: 10px 5px !important;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.liItem {
  display: flex;
  flex-direction: row;
  border-left: 1px solid var(--w-grey);
  align-items: center;
}
/* 
.cardNumbs {
  display:grid;
  margin: 1px;
  grid-template-columns: repeat(2, minmax(180px, 1fr));
  gap: 1em;
}

.cardNumbs .card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1em;
  margin: 1em;
  border-radius: 10px;
  background-color: var(--bg-contrast-soft);
  color: var(--text-menu);
  font-size: 0.9em;
  transition: 500ms;
}

.cardNumbs .card:hover {
  transform: scale3d(1.2, 1.2, 1.2);
} */

.cardItemNews {
  transition: all 0.4s;
  transform-origin: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.cardItemNews:hover {
  transform: scale(1.05);
  cursor: pointer;

}
