.bgOverlay {
  background-color: var(--bg-overlay) !important;
}

.bgOverlayDark {
  background-color: var(--bg-overlay-dark) !important;
}

.bgOverlayDarker {
  background-color: var(--bg-overlay-darker) !important;
}

.bgOverlayLight {
  background-color: var(--bg-overlay-light) !important;
}

.bgOverlayLighter {
  background-color: var(--bg-overlay-lighter) !important;
}

.bgWhite {
  background-color: var(--bs-white) !important;
}

.bgBlack {
  background-color: var(--bs-black) !important;
}

.bgBluerTransp {
  background-color: var(--bluer-transp);
}
