
#resultado {
  /* background-color: rgb(245, 245, 245); */
  padding: 30px;
  margin-top: 20px;
  border-radius: 10px;
  margin-bottom: 30px;
  text-align: center;
  vertical-align: middle;
}

#resultado td {
  font-size: 0.9em;
}

#resultado > tbody > tr:hover {
  background-color: var(--w-light-grey) !important;
}

.bg-div-svg-graph {
  color: var(--text-contrast-low);
  padding: 15px 20px;
  border-radius: 10px;
}

.container-simulador {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.td-parc {
  width: 100px;
}

.td-amort {
  width: 150px;
}

.td-jur {
  width: 100px;
}

.td-valor {
  width: 150px;
}

.td-title {
  font-size: 1.1em;
}

.gorow input {
  text-align: center;
}

.divradio {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.radio-label {
  padding-right: 5px;
}

.radios {
  display: inline;
}

.bet {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

thead {
  font-weight: 600;
}

.td-title-cols {
  border-left: 0.5px solid var(--w-light-grey);
  border-right: 0.5px solid var(--w-light-grey);
}

/* 
.bg-table-green-light td {
  background: var(--w-green-lighter-t) !important;
  border: 1px solid var(--w-green) !important;
}

.cGreen-dark.single-td-green-light {
  background: var(--w-greener-lighter-t) !important;
}

.bg-table-greener-light td {
  background: var(--w-greener-lighter-t) !important;
  border: 1px solid var(--w-green) !important;
}

.bg-table-red-light td {
  background: var(--w-red-light-t) !important;
}

.bg-table-grey-light td {
  background: var(--w-dark-grey-t) !important;
} */

/* .div-hide {
  display: none !important;
} */

/* @media (max-width: 600px) {
  .gorow {
    flex-direction: column;
  }

  .gocol600 {
    flex-direction: column;
  }

  .hide-600 {
    display: none;
  }

  .gorow label {
    width: 90%;
    margin: auto;
    text-align: center;
  }
}

@media (max-width: 900px) {
  .gorow900 {
    flex-direction: column !important;
    flex-wrap: nowrap !important;
  }
}

@media (max-width: 1024px) {
  .gorow1024 {
    flex-direction: column !important;
  }
} */
