.divEtapa {
  padding: 1em 0 1em 2em;
  margin: 2px;
}

.etAtual {
  border-left: 4px dotted var(--text-link);
  padding: 2em 0 2em 2em;
  /* background-color: var(--bg-contrast-soft); */
}

.etPendente {
  border-left: 3px dotted var(--bg-menu);
}

.etConcluida {
  border-left: 5px solid var(--bs-green);
}

.etapaHeader {
  justify-content: space-between;
  align-items: center;
}

.etapaHeader,
.etapaHeaderStart {
  display: flex !important;
  /* flex-direction: row !important; */
  /* align-items: center !important; */
}

.etapaHeaderStart svg {
  height: 1.4em;
  width: 1.4em;
}

.divAtual {
  background-color: var(--bg-contrast-soft-darker);
}

.etapaContainer {
  margin: 5px;
  border-radius: 10px;
  padding: 1em;
}

.rowConcluido {
  border-bottom: 1px solid var(--bg-menu);
  padding: 0.5em 0.7em 1em;
  margin-bottom: 1em;
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  border-radius: 6px;
}

.concluidoCheck {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.concluidoCheck .form-check-input,
.concluidoCheck.form-check {
  margin: 0 !important;
  padding: 0 !important;
}

.concluidoCheck .form-check-label {
  padding-left: 0.5em;
}

.andamentoLine {
  border-bottom: 1px solid var(--bg-menu);
}

.andamentoLineInfo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.headerSection {
  display: flex;
  align-items: center;
}

.divAtual .andamentoLine {
  border-bottom: 1px solid var(--bg-menu-soft);
}

@media screen and (max-width: 550px) {
  .etapaHeaderStart {
    display: flex !important;
    align-items:flex-start !important;
  }

  .headerSection {
    padding: 0.2em 0;
  }

  .andamentoLineInfo {
    display: flex;
    justify-content: start;
  }
  
}