.tran01 {
  transition: all 0.1s;
}

.tran02 {
  transition: all 0.2s;
}

.tran03 {
  transition: all 0.3s;
}

.tran04 {
  transition: all 0.4s;
}

.tran05 {
  transition: all 0.5s;
}

.tran06 {
  transition: all 0.6s;
}

.tran07 {
  transition: all 0.7s;
}

.tran08 {
  transition: all 0.8s;
}

.tran09 {
  transition: all 0.9s;
}

.tran10 {
  transition: all 1s;
}

.tran11 {
  transition: all 1.1s;
}

.tran12 {
  transition: all 1.2s;
}

.tran13 {
  transition: all 1.3s;
}

.tran14 {
  transition: all 1.4s;
}

.tran15 {
  transition: all 1.5s;
}

.tran16 {
  transition: all 1.6s;
}

.tran17 {
  transition: all 1.7s;
}

.tran18 {
  transition: all 1.8s;
}

.tran19 {
  transition: all 1.9s;
}

.tran20 {
  transition: all 2s;
}

.tGrow105:hover {
  transform: scale(1.05);
}

.tGrow11:hover {
  transform: scale(1.1);
}

.tGrow12:hover {
  transform: scale(1.2);
}

.tGrow13:hover {
  transform: scale(1.3);
}

.tGrow14:hover {
  transform: scale(1.4);
}

.tGrow15:hover {
  transform: scale(1.5);
}

.tGrow16:hover {
  transform: scale(1.6);
}

.tGrow17:hover {
  transform: scale(1.7);
}

.tGrow18:hover {
  transform: scale(1.8);
}

.tGrow19:hover {
  transform: scale(1.9);
}

.tGrow20:hover {
  transform: scale(2);
}

.tGrow21:hover {
  transform: scale(2.1);
}

.tGrow22:hover {
  transform: scale(2.2);
}

.tGrow23:hover {
  transform: scale(2.3);
}

.tGrow24:hover {
  transform: scale(2.4);
}

.tGrow25:hover {
  transform: scale(2.5);
}

.tGrow26:hover {
  transform: scale(2.6);
}

.tGrow27:hover {
  transform: scale(2.7);
}

.tGrow28:hover {
  transform: scale(2.8);
}

.tGrow29:hover {
  transform: scale(2.9);
}

.tGrow30:hover {
  transform: scale(3);
}

.hovPadding10:hover {
  padding: 10px;
} 

.hovPadding20:hover {
  padding: 20px;
}

.hovPadding30:hover {
  padding: 30px;
}

.hovPadding40:hover {
  padding: 40px;
}

.hovPadding50:hover {
  padding: 50px;
}

.hovPadding60:hover {
  padding: 60px;
}

/* grow svg */

.growSvg10:hover {
  height: 1em;
  width: 1em;
}

.growSvg11:hover {
  height: 1.1em;
  width: 1.1em;
}

.growSvg12:hover {
  height: 1.2em;
  width: 1.2em;
}

.growSvg13:hover {
  height: 1.3em;
  width: 1.3em;
}

.growSvg14:hover {
  height: 1.4em;
  width: 1.4em;
}

.growSvg15:hover {
  height: 1.5em;
  width: 1.5em;
}

.growSvg16:hover {
  height: 1.6em;
  width: 1.6em;
}

.growSvg17:hover {
  height: 1.7em;
  width: 1.7em;
}

.growSvg18:hover {
  height: 1.8em;
  width: 1.8em;
}

.growSvg19:hover {
  height: 1.9em;
  width: 1.9em;
}

.growSvg20:hover {
  height: 2em;
  width: 2em;
}

.growSvg21:hover {
  height: 2.1em;
  width: 2.1em;
}

.growSvg22:hover {
  height: 2.2em;
  width: 2.2em;
}

.growSvg23:hover {
  height: 2.3em;
  width: 2.3em;
}

.growSvg24:hover {
  height: 2.4em;
  width: 2.4em;
}

.growSvg25:hover {
  height: 2.5em;
  width: 2.5em;
}

.growSvg26:hover {
  height: 2.6em;
  width: 2.6em;
}

.growSvg27:hover {
  height: 2.7em;
  width: 2.7em;
}

.growSvg28:hover {
  height: 2.8em;
  width: 2.8em;
}

.growSvg29:hover {
  height: 2.9em;
  width: 2.9em;
}

.growSvg30:hover {
  height: 3em;
  width: 3em;
}