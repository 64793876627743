.bor05 {
    border-width: 0.5px !important;
    border-style: solid !important;
}

.bor06 {
    border-width: 0.6px !important;
    border-style: solid !important;
}

.bor07 {
    border-width: 0.7px !important;
    border-style: solid !important;
}

.bor08 {
    border-width: 0.8px !important;
    border-style: solid !important;
}

.bor09 {
    border-width: 0.9px !important;
    border-style: solid !important;
}

.bor1 {
    border-width: 1px !important;
    border-style: solid !important;
}

.bor15 {
    border-width: 1.5px !important;
    border-style: solid !important;
}

.bor2 {
    border-width: 2px !important;
    border-style: solid !important;
}

.bor3 {
    border-width: 3px !important;
    border-style: solid !important;
}

.bor4 {
    border-width: 4px !important;
    border-style: solid !important;
}

.borRad01 {
    border-radius: 1px !important;
}

.borRad02 {
    border-radius: 2px !important;
}

.borRad03 {
    border-radius: 3px !important;
}

.borRad04 {
    border-radius: 4px !important;
}

.borRad05 {
    border-radius: 5px !important;
}

.borRad06 {
    border-radius: 6px !important;
}

.borRad07 {
    border-radius: 7px !important;
}

.borRad08 {
    border-radius: 8px !important;
}

.borRad09 {
    border-radius: 9px !important;
}

.borRad10 {
    border-radius: 10px !important;
}

.borRad15 {
    border-radius: 15px !important;
}

.borRad20 {
    border-radius: 20px !important;
}

.borRad25 {
    border-radius: 25px !important;
}

.borRad30 {
    border-radius: 30px !important;
}

.borRad35 {
    border-radius: 35px !important;
}

.borRad40 {
    border-radius: 40px !important;
}

.borRad45 {
    border-radius: 45px !important;
}

.borRad50 {
    border-radius: 50px !important;
}
