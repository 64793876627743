.pdf_iframe {
  width: 100%;
  height: 100vh;
  border: none;
}

.content-doc {
  position: relative;
}

.content-doc iframe {
  top: 0;
  left: 0;
}

.content-doc img {
  top: 0;
  left: 0;
  max-width: 600px;
}
