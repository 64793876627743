.taskStatus {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1em;
  margin: 1em 0;
}

.taskStatusCol {
  display: flex;
  flex-direction: column;
  border-radius: 1em;
  background-color: var(--bg-menu);
  padding: 1em;
}

@media screen and (max-width: 900px) {
  .taskStatus {
    grid-template-columns: repeat(2, 1fr);
  }
}
