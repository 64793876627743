.menuSectionCard {
  width: 15%;
  min-width: 90px;
  padding: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  transition: transform 700ms;
}

.menuSectionCard > span {
  font-size: 0.8em;
  padding-top: 0.5em;
  font-weight: 300;
  font-family: Mulish, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.menuSectionCard:hover {
  transform: scale3d(1.1, 1.1, 1.1);
}

.menuSectionCard > img {
  width: 70px;
}

@media screen and (max-width: 768px) {
  .menuSectionLine {
    flex-wrap: wrap;
  }

  .menuSectionCard img {
    width: 50%;
  }
}
