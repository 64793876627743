.theme {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.theme > svg {
  fill: var(--menu-svg);
}

.theme > svg:hover {
  fill: var(--menu-svg-hover);
}

.theme > .themeActive, .themeActive  {
  fill: var(--menu-svg-active);
}

.divTheme > .dropdown > .dropdown-menu  {
  background-color: var(--bg-menu) !important;
  color: var(--text-menu);
}

.navTheme {
  display: flex;
  flex-direction: row;
  justify-content: end;
  width: 100%;
  padding: 3px;
}