@import url('https://fonts.googleAPIs.com/css2?family=Mulish:ital,wght@0,200..1000;1,200..1000&family=Roboto+Flex:opsz,wght@8..144,100..1000&display=swap');

:root {
  color-scheme: light dark;

  --bs-violet: #8f00ff;
  --bs-light-blue: #4a67cd;
  --bs-light-cyan: #00c3ff;
  --bs-dark-green: #196c00;
  --bs-dark-pink: #f60087;
  --bs-dark-rose: #6f003d;
  --bs-dark-rose-light: #723858;
  --bs-red-dark: #ff2e2e;
  --bluer-transp: #00237be2 !important;
  --light-blue-transp: #2e4cb672 !important;
  --green-dark: #1f8300;
  --green-light: #308930;
  --greener-light: #00c400ca;
  --red-light: #aa0011c2;
  --grey-light: #636363b9;

  --bs-black: #000;
  --bs-white: #fff;

  --bs-gray-100: #f8f9fa;
  --bs-gray-150: #f6f6f6;
  --bs-gray-200: #e9ecef;
  --bs-gray-250: #dde1e4;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-750: #444a50;
  --bs-gray-800: #343a40;
  --bs-gray-850: #262b2f;
  --bs-gray-900: #212529;
  --bs-gray-950: #1a1d20;

  --bs-blue: light-dark(#1e337c, var(#9cafe7)) !important;
  --bs-purple: light-dark(#4b0082, var(#bd61ff)) !important;
  --bs-indigo: light-dark(#a020f0, var(#bc62f4)) !important;
  --bs-pink: light-dark(#d63384, var(#df5d9e)) !important;
  --bs-red: light-dark(#9e0000, var(#e24040)) !important;
  --bs-orange: light-dark(#fd7e14, var(#fd7e14)) !important;
  --bs-yellow: light-dark(#ffa600, var(#ffa600)) !important;
  --bs-green: light-dark(#2cbe00, var(#259e00)) !important;
  --bs-teal: light-dark(#00a372, var(#00a372)) !important;
  --bs-cyan: light-dark(#0073ff, var(#2e8cff)) !important;

  --bs-primary: var(--c-blue);
  --bs-secondary: var(--c-gray-600);
  --bs-success: var(--c-green);
  --bs-info: var(--c-cyan);
  --bs-warning: var(--c-yellow);
  --bs-danger: var(--c-red);
  --bs-light: var(--c-gray-100);
  --bs-dark: var(--c-gray-800);
  --bs-violet: var(--c-purple);

  --bg-primary: var(--c-primary);
  --bg-secondary: var(--c-secondary);
  --bg-success: var(--c-success);
  --bg-info: var(--c-info);
  --bg-warning: var(--c-warning);
  --bg-danger: var(--c-danger);
  --bg-light: var(--c-light);
  --bg-dark: var(--c-dark);
  --bg-violet: var(--c-violet);

  --c-violet: #8f00ff;
  --c-light-blue: #4a67cd;
  --c-light-cyan: #00c3ff;
  --c-dark-green: #196c00;
  --c-dark-pink: #f60087;
  --c-dark-rose: #6f003d;
  --c-dark-rose-light: #723858;
  --c-red-dark: #e24040;
  --bluer-transp: #00237be2 !important;
  --light-blue-transp: #2e4cb672 !important;
  --green-dark: #1f8300;
  --green-light: #308930;
  --greener-light: #00c400ca;
  --red-light: #aa0011c2;
  --grey-light: #636363b9;

  --c-blood: #9e0000;

  --c-black: #000;
  --c-white: #fff;

  --c-gray-100: #f8f9fa;
  --c-gray-150: #f6f6f6;
  --c-gray-200: #e9ecef;
  --c-gray-250: #dde1e4;
  --c-gray-300: #dee2e6;
  --c-gray-400: #ced4da;
  --c-gray-500: #adb5bd;
  --c-gray-600: #6c757d;
  --c-gray-700: #495057;
  --c-gray-750: #444a50;
  --c-gray-800: #343a40;
  --c-gray-850: #262b2f;
  --c-gray-900: #212529;
  --c-gray-950: #1a1d20;

  --c-blue: light-dark(#1e337c, #9cafe7) !important;
  --c-purple: light-dark(#4b0082, #bd61ff) !important;
  --c-indigo: light-dark(#a020f0, #bc62f4) !important;
  --c-pink: light-dark(#d63384, #df5d9e) !important;
  --c-red: light-dark(#9e0000, #e24040) !important;
  --c-orange: light-dark(#fd7e14, #fd7e14) !important;
  --c-yellow: light-dark(#ffa600, #ffa600) !important;
  --c-green: light-dark(#2cbe00, #259e00) !important;
  --c-teal: light-dark(#00a372, #00a372) !important;
  --c-cyan: light-dark(#0073ff, #2e8cff) !important;

  --c-primary: var(--c-blue);
  --c-secondary: var(--c-gray-600);
  --c-success: var(--c-green);
  --c-info: var(--c-cyan);
  --c-warning: var(--c-yellow);
  --c-danger: var(--c-red);
  --c-light: var(--c-gray-100);
  --c-dark: var(--c-gray-800);
  --c-violet: var(--c-purple);

  --c-secondary: light-dark(var(--bs-gray-600), var(--bs-gray-400)) !important;

  --bg-overlay-dark-soft: rgba(0, 0, 0, 0.4) !important;
  --bg-overlay-dark: rgba(0, 0, 0, 0.6) !important;
  --bg-overlay-darker: rgba(0, 0, 0, 0.8) !important;
  --bg-overlay-darkest: rgba(0, 0, 0, 0.9) !important;

  --bg-overlay-light-soft: rgba(255, 255, 255, 0.4) !important;
  --bg-overlay-light: rgba(255, 255, 255, 0.6) !important;
  --bg-overlay-lighter: rgba(255, 255, 255, 0.8) !important;

  --text-overlay-dark: rgba(255, 255, 255, 0.9) !important;
  --text-overlay-light: rgba(0, 0, 0, 0.9) !important;

  /* default */

  --font-title: 'Mulish', Arial, Helvetica, sans-serif;
  --font-soft: 'Mulish', Arial, Helvetica, sans-serif;
  --font-regular: 'Roboto Flex', Arial, Helvetica, sans-serif;

  /* common */
  --bg: light-dark(var(--c-gray-100), var(--c-gray-800)) !important;
  --bg-contrast: light-dark(var(--c-gray-200), var(--c-gray-700)) !important;
  --bg-contrast-soft: light-dark(
    var(--c-gray-150),
    var(--c-gray-750)
  ) !important;

  --bg-contrast-soft-darker: light-dark(
    var(--c-gray-200),
    var(--c-gray-850)
  ) !important;

  --bg-contrast-soft-lighter: light-dark(
    var(--c-white),
    var(--c-gray-750)
  ) !important;

  --text: light-dark(var(--c-gray-800), var(--c-gray-100)) !important;
  --text-contrast: light-dark(var(--c-black), var(--c-white)) !important;
  --text-contrast-low: light-dark(
    var(--c-gray-600),
    var(--c-gray-500)
  ) !important;
  --text-contrast-lower: light-dark(
    var(--c-gray-200),
    var(--c-gray-700)
  ) !important;
  --text-contrast-color: light-dark(
    var(--c-light-blue),
    var(--c-yellow)
  ) !important;

  --text-contrast-color-diff: light-dark(
    var(--c-light-blue),
    var(--c-light-blue)
  ) !important;

  --text-contrast-color-blue: var(--c-light-blue) !important;
  --text-contrast-color-yellow: var(--c-yellow) !important;
  --text-contrast-color-green: var(--c-dark-green) !important;
  --text-contrast-color-pink: var(--c-dark-pink) !important;
  --text-contrast-color-rose: light-dark(
    var(--c-dark-rose),
    var(--c-dark-rose-light)
  ) !important;
  --text-contrast-color-red: light-dark(
    var(--c-red),
    var(--c-red-dark)
  ) !important;

  --text-link: light-dark(var(--c-cyan), var(--c-light-cyan)) !important;

  /* menu */
  --bg-menu: light-dark(var(--c-gray-200), var(--c-gray-700)) !important;
  --text-menu: light-dark(var(--c-gray-700), var(--c-gray-200)) !important;
  --text-menu-soft: light-dark(
    var(--c-gray-500),
    var(--c-gray-400)
  ) !important;

  --bg-menu-soft: light-dark(var(--c-gray-150), var(--c-gray-850)) !important;

  --menu-svg: light-dark(var(--c-gray-700), var(--c-gray-200)) !important;
  --menu-svg-active: light-dark(var(--c-yellow), var(--c-yellow)) !important;

  --bg-menu-hover: light-dark(
    var(--c-gray-700),
    var(--c-gray-900)
  ) !important;
  --text-menu-hover: light-dark(var(--c-gray-200), var(--c-white)) !important;
  --menu-svg-hover: light-dark(var(--c-white), var(--c-gray-100)) !important;

  /* tooltip */
  --bg-tooltip: light-dark(var(--c-gray-400), var(--c-gray-600)) !important;
  --text-tooltip: light-dark(var(--c-black), var(--c-white)) !important;

  /* dropdown */
  --bg-dropdown: var(--bg-menu) !important;
  --text-dropdown: var(--text-menu) !important;

  --bg-dropdown-hover: light-dark(
    var(--bg-gray-700),
    var(--bg-gray-300)
  ) !important;
  --text-dropdown-hover: light-dark(
    var(--c-white),
    var(--c-black)
  ) !important;

  /* navbar */
  --bg-navbar: light-dark(var(--c-gray-400), var(--c-gray-900)) !important;
  --text-navbar: light-dark(var(--c-gray-600), var(--c-gray-400)) !important;

  /* footer */
  --bg-footer: light-dark(var(--c-gray-400), var(--c-gray-900)) !important;
  --text-footer: light-dark(var(--c-gray-600), var(--c-gray-400)) !important;
  /*  */

  /* max-container main width */
  --max-container: 1200px;

  /* spinner */
}

html {
  scroll-behavior: smooth;
}

* {
  margin: 0;
  padding: 0;
}

body {
  font-family: var(--font-regular);
  background-color: var(--bg);
  color: var(--text);
}

a {
  color: var(--text-link) !important;
  text-decoration: none !important;
}

a > .btn-link,
.spanLinkButton > svg {
  color: var(--text-link) !important;
  fill: var(--text-link) !important;
  text-decoration: none !important;
}

.setWidth {
  width: 100%;
  padding: 5px;
  max-width: var(--max-container);
  margin: auto;
}

.light {
  color-scheme: light;
}

.dark {
  color-scheme: dark;
}

.navtheme {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: end;
  padding: 5px;
}

.textContrast {
  color: var(--text-contrast);
}

.textContrastColor {
  color: var(--text-contrast-color);
}

.textContrastLow {
  color: var(--text-contrast-low);
}

.textMenuSoft {
  color: var(--text-menu-soft);
}

.mousePointerHover:hover {
  cursor: pointer;
}

.divIconsUi.hovGreen:hover span,
.divIconsUi.hovGreen:hover svg {
  color: var(--bs-green);
}

.homeLogo {
  width: 50%;
  max-width: 200px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.mAuto {
  margin: auto;
}

.formContainerRounded {
  border-radius: 15px;
  padding: 20px;
  background-color: var(--bg-contrast);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
}

.bgTransparent {
  background-color: transparent !important;
}

.tooltip {
  position: relative;
  font-size: 0.7em !important;
  background-color: var(--bg-menu-soft) !important;
  border: unset;
  display: inline;
  width: fit-content !important;
  text-align: center;
  height: auto !important;
  z-index: 0;
  inset: auto auto 0.2em !important;
}

.tooltip .tooltip-inner {
  background-color: var(--bg-tooltip) !important;
  color: var(--text-tooltip);
  border-radius: 0.3em;
}

.tooltip-arrow::before {
  border-top-color: var(--bg-tooltip) !important;
}

.breakAlways {
  break-inside: always !important;
}

.breakAuto {
  break-inside: auto !important;
}

.breakAvoid {
  break-inside: avoid !important;
}

.breakAfter {
  break-after: always !important;
}

.breakBefore {
  break-before: always !important;
}

.breakWord {
  word-break: break-word !important;
}

.noBreak {
  white-space: nowrap !important;
}

.whiteSpacePre {
  white-space: pre !important;
}

@media screen and (prefers-reduced-motion: reduce) {
  html {
    scroll-behavior: auto;
  }
}
